.container {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.home-wrapper {
  background-color: #d9d5c4;
  border: 5px solid #f5f3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 8px;
  max-width: 800px;
  width: 80%;
}

.home-image {
  width: 70%;
  height: auto;
  margin-bottom: 30px;
  display: block;
  max-width: 500px;
  object-fit: contain;
}

.container h1 {
  /* font-size: clamp(20px, 4.25vw, 30px); */
  font-size: 30px;
  color: #666;
  text-shadow: 1.2px 1.2px 0.5px white;
  margin-bottom: 20px;
  width: 100%;
}

.container p {
  font-size: clamp(15px, 3.4vw, 20px);
  color: #666;
  text-shadow: 1.2px 1.2px 0.5px white;
  margin-bottom: 20px;
}

.container h1,
.container p {
  margin-left: auto;
  margin-right: auto;
}

.quiz-template-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}

.quiz-button {
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  text-align: center;
  font-size: clamp(14px, 3vw, 18px);
  font-weight: bold;
  text-decoration: none;
  color: #666;
  text-shadow: 1.2px 1.2px 0.5px white;
  transition: all 0.3s ease;
}

.quiz-button:hover {
  background: #f5f3e3;
  border-color: #bdb76b;
}

@media (max-width: 600px) {
  .home-image {
    width: 70%;
  }

  .container {
    padding: 10px;
  }
}
