/* Base styles for the vendors page */
:root {
  --primary-color: #2962ff;
  --primary-light: #768fff;
  --primary-dark: #0039cb;
  --secondary-color: #f5f5f5;
  --text-color: #333;
  --border-color: #e0e0e0;
  --success-color: #4caf50;
  --error-color: #f44336;
}
.vendors-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.vendors-heading {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.vendors-error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

/* Region and country styles */
.vendors-regions-container {
  margin-top: 2rem;
}

.vendors-regions-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.vendors-region-title {
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.vendors-countries-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.vendors-country-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #e0e0e0;
}

.vendors-country-card:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Country listings view */
.vendors-country-listings {
  margin-top: 2rem;
}

.vendors-country-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.vendors-return-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.vendors-return-button:hover {
  background-color: var(--primary-dark);
}

.vendors-listings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.vendors-no-listings {
  text-align: center;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 2rem 0;
}

/* Listing item styles */
.vendors-listing-item {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.vendors-listing-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.vendors-listing-header {
  padding: 1rem;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vendors-listing-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.vendors-country-badge {
  background-color: var(--primary-color);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.vendors-listing-services {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
}

.vendors-service-badge {
  background-color: #e3f2fd;
  color: #1565c0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
}

.vendors-listing-details {
  padding: 1rem;
  flex: 1;
}

.vendors-listing-details p {
  margin: 0.5rem 0;
}

.vendors-listing-description {
  margin-top: 1rem;
  color: #666;
}

/* Service Filter Component Styles */
.service-filter {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid var(--border-color);
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.filter-header h3 {
  margin: 0;
  color: var(--text-color);
}

.clear-filters-button {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.clear-filters-button:hover:not(:disabled) {
  background-color: #f0f0f0;
  border-color: #999;
}

.clear-filters-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
}

.service-tag {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}

.service-tag:hover {
  background-color: #e8e8e8;
}

.service-tag.selected {
  background-color: var(--primary-light);
  color: white;
  border-color: var(--primary-color);
}

.matching-mode-toggle {
  margin: 1rem 0;
  padding: 0.75rem;
  background-color: #f0f0f0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.matching-mode-label {
  font-weight: 500;
  color: var(--text-color);
}

.toggle-switch {
  position: relative;
  background-color: #e0e0e0;
  border-radius: 24px;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  min-height: 36px;
  transition: background-color 0.3s;
}

.toggle-switch.or-mode {
  background-color: #bbdefb;
}

.toggle-switch.and-mode {
  background-color: #c8e6c9;
}

.toggle-indicator {
  position: absolute;
  width: 50%;
  height: calc(100% - 4px);
  top: 2px;
  left: 2px;
  background-color: white;
  border-radius: 20px;
  transition: transform 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.and-mode .toggle-indicator {
  transform: translateX(calc(100% - 4px));
}

.or-label,
.and-label {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  z-index: 1;
  transition: color 0.3s;
  text-align: center;
  flex: 1;
}

.or-label {
  margin-right: auto;
}

.and-label {
  margin-left: auto;
}

.or-label.active,
.and-label.active {
  color: var(--primary-dark);
  font-weight: 600;
}

.active-filters {
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #f0f0f0;
  border-radius: 6px;
  font-size: 0.9rem;
}

.active-filters p {
  margin: 0.25rem 0;
}

@media (max-width: 768px) {
  .vendors-listings-grid {
    grid-template-columns: 1fr;
  }

  .vendors-countries-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .vendors-country-header {
    flex-direction: column;
    gap: 1rem;
  }

  .matching-mode-toggle {
    flex-direction: column;
    align-items: flex-start;
  }

  .toggle-switch {
    width: 100%;
  }
}
