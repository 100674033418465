/* Base settings styles */
.settings-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.settings-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.settings-title {
  margin-top: 0;
  margin-bottom: 25px;
  color: #333;
  text-align: center;
}

.settings-section {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.settings-section:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.settings-section h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #444;
}

/* Account info display */
.account-info p {
  margin: 10px 0;
  line-height: 1.5;
}

.business-description {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
  margin-top: 5px;
  white-space: pre-line;
}

/* Action buttons */
.account-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.action-button {
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-width: 200px;
}

.action-button:hover {
  background-color: #0069d9;
}

.password-button {
  background-color: #007bff;
}

.password-button:hover {
  background-color: #0069d9;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.modal-header h3 {
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
}

.close-btn:hover {
  color: #333;
}

.modal-body {
  padding: 20px;
}

/* Form elements */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
  min-height: 80px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.cancel-button {
  padding: 10px 15px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #e9ecef;
}

.update-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.update-button:disabled {
  background-color: #96c4ea;
  cursor: not-allowed;
}

.update-button:hover:not(:disabled) {
  background-color: #0069d9;
}

/* Messages */
.error-message,
.success-message {
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .settings-card {
    padding: 20px 15px;
  }

  .modal-content {
    width: 95%;
  }

  .account-actions {
    flex-direction: column;
  }

  .action-button {
    width: 100%;
  }
}
