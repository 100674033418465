.auth-container {
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.auth-wrapper {
  background-color: #d9d5c4;
  border: 5px solid #f5f3e3;
  border-radius: 8px;
  padding: 40px;
  width: 100%;
  max-width: 400px;
}

.auth-wrapper h2 {
  text-align: center;
  color: #666;
  text-shadow: 1.2px 1.2px 0.5px white;
  margin-bottom: 30px;
  font-size: 24px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group input {
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  background: white;
}

.form-group input:focus {
  outline: none;
  border-color: #bdb76b;
}

.auth-button {
  padding: 12px;
  background: white;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  color: #666;
  font-weight: bold;
  cursor: pointer;
  text-shadow: 1.2px 1.2px 0.5px white;
  transition: all 0.3s ease;
}

.auth-button:hover {
  background: #f5f3e3;
  border-color: #bdb76b;
}

.auth-error {
  background-color: #ffebee;
  border: 1px solid #f44336;
  color: #d32f2f;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
}

.auth-link {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.auth-link a {
  color: #007bff;
  text-decoration: none;
}

.auth-link a:hover {
  text-decoration: underline;
}
