.mobile-nav {
  display: block;
  padding-right: 20px;
  position: relative;
  z-index: 101;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
}

.hamburger span {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
  position: absolute;
  border-radius: 2px;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.hamburger span:nth-child(3) {
  bottom: 0;
}

.hamburger.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 50%;
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 50%;
}

.mobile-menu {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d9d5c4;
  padding: 2rem;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  border-top: 5px solid #f5f3e3;
  overflow-y: auto;
  z-index: 100;
}

.mobile-menu.open {
  transform: translateX(0);
  z-index: 101;
}

/* MOBILE TITLES AND LINKS */

.mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

.mobile-content a,
.mobile-content p,
.mobile-link {
  color: #666;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 1.2px 1.2px 0.5px white;
  padding: 0.5rem;
  border: 2px solid transparent;
  border-radius: 5px;
  background: none;
  cursor: pointer;
  width: 80%;
}
.mobile-content p {
  margin: 0;
}

.mobile-content a:hover,
.mobile-link:hover {
  background-color: #e8e4c9;
  border: 2px solid white;
  width: 30%;
}

.mobile-divider {
  width: 90%;
  border: none;
  border-top: 2px solid #f5f3e3;
  margin: 0.5rem 0;
}

@media (min-width: 850px) {
  .mobile-nav {
    display: none;
  }
}

.mobile-heading {
  font-size: 30px !important;
}
