.navbar {
  display: block;
}

.desktop-nav {
  display: none;
  padding-right: 60px;
}

.desktop-nav ul {
  display: flex;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.desktop-nav li {
  position: relative;
  font-size: 18px;
}

.desktop-nav a {
  text-decoration: none;
  color: #666;
  text-shadow: 1.2px 1.2px 0.5px white;
  font-weight: bold;
}

@media (min-width: 850px) {
  .desktop-nav {
    display: block;
  }
}

.account-dropdown,
.fallacy-dropdown {
  position: relative;
  display: inline-block;
}

.account-dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  background-color: #d9d5c4;
  padding: 8px;
  border: 3px solid white;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  min-width: 150px;
  white-space: nowrap;
  box-sizing: border-box;
}

.account-dropdown:hover .account-dropdown-content,
.fallacy-dropdown:hover .account-dropdown-content {
  display: block;
}

.account-dropdown-content::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 25px;
  background: transparent;
}

.account-dropdown-content a,
.dropdown-logout {
  color: #666;
  text-shadow: 1.2px 1.2px 0.5px white;
  font-weight: bold;
  text-decoration: none;
  padding: 6px 8px;
  display: block;
  transition: background-color 0.3s ease;
  text-align: left;
  border: 2px solid transparent;
  box-sizing: border-box;
  width: 100%;
  background: none;
  font-size: 16px;
  cursor: pointer;
  margin: 2px 0;
}

.account-dropdown-content a:hover,
.dropdown-logout:hover {
  background-color: #e8e4c9;
  border: 2px solid white;
  border-radius: 5px;
  margin: 2px 0;
  width: 100%;
  box-sizing: border-box;
}

.nav-login-button {
  background: none;
  border: none;
  color: #666;
  text-shadow: 1.2px 1.2px 0.5px white;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  padding: 3px 6px;
  border: 2px solid transparent;
  border-radius: 5px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
}

.desktop-nav li:first-child a {
  padding: 3px 6px;
  display: block;
  border: 2px solid transparent;
  border-radius: 5px;
}

.desktop-nav li:first-child a:hover {
  background-color: #e8e4c9;
  border: 2px solid white;
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .desktop-nav li {
    text-align: center;
  }
}
