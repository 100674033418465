/* Updated styles for card-like appearance */

/* Main container styling */
.listings-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

/* Header section */
.listings-header {
  margin-bottom: 30px;
  display: flex;
}

.create-listing-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
  box-shadow: 0 3px 5px rgba(0, 123, 255, 0.2);
}

.create-listing-button:hover {
  background-color: #0069d9;
  box-shadow: 0 5px 8px rgba(0, 123, 255, 0.3);
}

/* Listings grid layout - single column */
.listings-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;
}

/* Individual listing card styling */
.listing-item {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid #eaedf2;
  display: flex;
  flex-direction: column;
}

.listing-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Listing header styling */
.listing-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.listing-header h3 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.3rem;
  text-align: center;
}

/* Country badge styling - now in its own container */
.country-badge-container {
  text-align: left;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.country-badge {
  background-color: #e9ecef;
  color: #495057;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.85rem;
  font-weight: 600;
  display: inline-block;
}

/* Listing services styles */
.listing-services {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 15px;
}

.service-badge {
  background-color: #6c757d;
  color: white;
  font-size: 0.75rem;
  padding: 5px 12px;
  border-radius: 20px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Clickable service badges */
.service-badge.clickable {
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;
}

.service-badge.clickable:hover {
  background-color: #5a6268;
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.service-badge.clickable:active {
  transform: translateY(0);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
}

.service-badge.clickable.selected {
  background-color: #28a745;
  color: white;
}

/* Listing details styling */
.listing-details {
  flex-grow: 1;
  margin-bottom: 20px;
}

.listing-details p {
  margin: 8px 0;
  font-size: 0.9rem;
  color: #555;
}

.listing-details strong {
  color: #333;
}

.listing-description {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px dashed #eee;
  font-style: italic;
  color: #666;
}

/* Listing actions styling */
.listing-actions {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid #eee;
  gap: 10px;
}

/* Edit button styling */
.edit-button {
  background-color: #17a2b8;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 0.9rem;
  flex-grow: 1;
}

.edit-button:hover {
  background-color: #138496;
  transform: translateY(-2px);
}

/* Delete button styling */
.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9rem;
  flex-grow: 1;
}

.delete-button:hover {
  background-color: #c82333;
}

/* Update button styling for the modal */
.update-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(40, 167, 69, 0.2);
}

.update-button:hover {
  background-color: #218838;
}

/* Status messages */
.error-message,
.success-message {
  padding: 12px 15px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Section headings */
.listings-section h2 {
  margin-bottom: 20px;
  color: #343a40;
  font-size: 1.4rem;
  padding-bottom: 10px;
  border-bottom: 2px solid #e9ecef;
}

/* No listings message */
.no-listings {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: #6c757d;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-top: 15px;
}

/* Read-only field styling */
/* Modified readonly-field to match country-badge styling */
.readonly-field {
  background-color: #e9ecef;
  color: #495057;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.85rem;
  font-weight: 600;
  display: inline-block;
  border: none; /* Remove border to match country-badge */
}

.field-hint {
  color: #6c757d;
  font-size: 0.8rem;
  margin-top: 5px;
  font-style: italic;
}

/* To match the container styling, you could wrap readonly-field in a div with this class */
.readonly-field-container {
  text-align: left;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.modal-header h3 {
  margin: 0;
  color: #343a40;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #777;
}

.modal-body {
  padding: 20px;
}

/* Form styling */
.listing-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.company-info {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.company-info h4 {
  margin-top: 0;
  color: #343a40;
}

.company-info p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #343a40;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
}

.selection-hint {
  font-size: 0.85rem;
  color: #6c757d;
  margin-bottom: 8px;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.service-tag {
  background-color: #e9ecef;
  color: #495057;
  border: 1px solid #ced4da;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}

.service-tag:hover {
  background-color: #dee2e6;
}

.service-tag.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.selection-error {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 5px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.create-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.2);
}

.create-button:hover {
  background-color: #0069d9;
}

.create-button:disabled,
.update-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .listing-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .country-badge {
    align-self: flex-start;
  }

  .listings-grid {
    max-width: 100%;
  }

  .listing-actions {
    flex-direction: column;
  }

  .edit-button,
  .delete-button {
    width: 100%;
  }

  .button-group {
    flex-direction: column;
  }

  .cancel-button,
  .create-button,
  .update-button {
    width: 100%;
  }
}
